
// normalize
dialog {
    background: transparent;
    border: 0;
    color: inherit;
    max-width: unset;
    padding: 0;
}

dialog::backdrop {
    background: rgba(0,0,0,0); // none, really
}

#video-overlay {
    position: absolute;
    z-index: 900;
    top: 0; left: 0; right: 0;

    width: 100vw;
    height: 0;
    padding-bottom: 56.25%; // 16:9
    margin: 0;

    .video-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    // close btn
    .btn-close {
        @include btn-unstyle;
        position: absolute;
        right: var(--size); top: var(--size);
        width: var(--size-md); height: var(--size-md);
        cursor: pointer;
        z-index: 999;

        &:before {
            content: url('/images/close.svg');
        }
    }

    // Show/hide
    overflow: hidden;
    transform: translateY(-100%);
    transition: transform 0.4s ease-in-out;
}


html.modal-open {
    #video-overlay[open]  {
        transform: translateY(0%);
    }
}
