
footer {
    background-color: var(--burgundy);
    color: var(--mustard);
    text-align: center;

    position: relative;
    padding: calc(8.125vw + var(--size-lg) * 2) var(--size-lg) var(--size-lg);

    // clip along the paper tear. polygon: X Y, X Y etc.
    // can use vw all around here cause the tearing is at the top (no need to calc height)
    clip-path: polygon(0vw 100vw, 100vw 100vw, 100vw 2vw, 73vw 4vw, 55vw 7vw, 44vw 3vw, 0vw 5vw);

    .partners {
        @extend %container;
        display: grid;
        grid-template-columns: repeat(4, minmax(10vw, 200px));
        gap: var(--size-lg);
        align-items: center;
        justify-content: center;
    }
}

.back-to-top {
    display: block;
    background: url('../images/back-to-top.svg') center / contain no-repeat;
    width: var(--size-lg); height: var(--size-lg);
    position: absolute;
    top: calc($tear_height + var(--size-lg));
    right: var(--size-md);
}
