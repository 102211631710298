
.home-intro {
    position: relative;
    padding-block: 12vw;
    color: black;
    text-align: center;

    @include fs(48);
    font-weight: $fw-xbold;

    &:before {
        content: '';
        background-color: var(--mustard);

        position: absolute;
        top: -$tear_height; bottom: 0;
        left: 0; right: 0;

        // clip along the paper tear. polygon: X Y, X Y etc.
        // can use vw all around here cause the tearing is at the top (no need to calc height)
        clip-path: polygon(0vw 100vw, 100vw 100vw, 100vw 2vw, 73vw 4vw, 55vw 7vw, 44vw 3vw, 0vw 5vw);
        z-index: $zindex_below_all;
    }

    .container {
        max-width: 60vw;
    }

    @include media(small) {
        &:after {
            content: '';
            background: url('../images/tear.png') center / 200% no-repeat;
            position: absolute;
            top: 0; transform: translateY(-75%);
            left: 0; right: 0;
            height: $tear_height * 2;

            // sit on top, do not disturb
            z-index: $zindex_tear;
            pointer-events: none;
        }
    }

    .photo-6 {
        position: absolute;
        left: 0; top: 0;
        transform: rotate(-5deg) translate(-15%, -12vw);
    }
    .photo-7 {
        position: absolute;
        right: 0; top: 0;
        transform: rotate(5deg) translate(25%, 5%) scale(0.8);
    }

    @include media(desktop) {
        padding-block: var(--size-md);

        .photo-6 {
            transform: rotate(-5deg) translate(-15%, 2vw);
        }
        .photo-7 {
            top: 100%;
            transform: rotate(5deg) translate(-25%, -25%) scale(0.8);
        }
    }
}
