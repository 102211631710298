.sr-only {
  position: absolute !important;
  top: auto;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.main-video .video-title,
.main-video .video-player, footer .partners, .centered, .container {
  max-width: var(--content-width);
  margin-inline: auto;
}

.video-link img {
  aspect-ratio: 1/1;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
}

.page-single .video-wrapper, .video-link img {
  overflow: hidden;
  border-radius: var(--border-radius);
}

.page-single .video-wrapper, .video-link img {
  border: 2px solid;
}

.related-videos:before, .page-about, .home-videos #videos {
  background: url("/images/texture.jpg") center/cover no-repeat;
  background-attachment: fixed;
}

:root {
  --color-page: #000000;
  --color-text: #ffffff;
  --teal: #607b7a;
  --burgundy: #662a29;
  --mustard: #f1aa4c;
  --font: "Raleway", sans-serif;
  --border-radius: 8vw;
  --content-width: 86vw;
  --size: 4vw;
  --size-xs: 2vw;
  --size-sm: 3vw;
  --size-md: 6vw;
  --size-lg: 8vw;
  --size-xl: 15vw;
}
@media only screen and (min-width: 1200px) {
  :root {
    --border-radius: 3rem;
    --content-width: 75vw;
    --size: 2rem;
    --size-xs: 0.5rem;
    --size-sm: 1rem;
    --size-md: 3rem;
    --size-lg: 4rem;
    --size-xl: 7rem;
  }
}
@media only screen and (min-width: 1920px) {
  :root {
    --border-radius: 5rem;
    --content-width: 1700px;
  }
}

* {
  box-sizing: border-box;
}

html {
  background-color: var(--color-page);
  color: var(--color-text);
  font-family: var(--font);
  line-height: 1.3;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: clamp(16px, 3.25vw, 8vw);
}
@media only screen and (min-width: 768px) {
  html {
    font-size: min(max(1rem, 2.734375vw), 1.875rem);
  }
}
@media only screen and (min-width: 1200px) {
  html {
    font-size: min(max(1rem, 1.328125vw), 1.875rem);
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  margin: 0;
}
.no-scroll body {
  overflow: hidden;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.centered {
  display: inline-block;
}

.container,
.content {
  position: relative;
  z-index: 4;
}

.photo {
  border: var(--size-xs) solid white;
  box-shadow: 0 0 0.25rem 0.25rem rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 991px) {
  .photo {
    border: 1vw solid white;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--burgundy);
  border: 2px solid var(--burgundy);
  border-radius: 2em;
  padding: 0.5em 2em;
}
.btn:active, .btn.active {
  border-color: var(--color-text);
}

/* raleway-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/raleway-v29-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("../fonts/raleway-v29-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-v29-latin-regular.woff2") format("woff2"), url("../fonts/raleway-v29-latin-regular.woff") format("woff"), url("../fonts/raleway-v29-latin-regular.ttf") format("truetype"), url("../fonts/raleway-v29-latin-regular.svg#Raleway") format("svg"); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/raleway-v29-latin-500.eot"); /* IE9 Compat Modes */
  src: url("../fonts/raleway-v29-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-v29-latin-500.woff2") format("woff2"), url("../fonts/raleway-v29-latin-500.woff") format("woff"), url("../fonts/raleway-v29-latin-500.ttf") format("truetype"), url("../fonts/raleway-v29-latin-500.svg#Raleway") format("svg"); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/raleway-v29-latin-600.eot"); /* IE9 Compat Modes */
  src: url("../fonts/raleway-v29-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-v29-latin-600.woff2") format("woff2"), url("../fonts/raleway-v29-latin-600.woff") format("woff"), url("../fonts/raleway-v29-latin-600.ttf") format("truetype"), url("../fonts/raleway-v29-latin-600.svg#Raleway") format("svg"); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/raleway-v29-latin-700.eot"); /* IE9 Compat Modes */
  src: url("../fonts/raleway-v29-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-v29-latin-700.woff2") format("woff2"), url("../fonts/raleway-v29-latin-700.woff") format("woff"), url("../fonts/raleway-v29-latin-700.ttf") format("truetype"), url("../fonts/raleway-v29-latin-700.svg#Raleway") format("svg"); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/raleway-v29-latin-800.eot"); /* IE9 Compat Modes */
  src: url("../fonts/raleway-v29-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/raleway-v29-latin-800.woff2") format("woff2"), url("../fonts/raleway-v29-latin-800.woff") format("woff"), url("../fonts/raleway-v29-latin-800.ttf") format("truetype"), url("../fonts/raleway-v29-latin-800.svg#Raleway") format("svg"); /* Legacy iOS */
}
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.25em;
}

p {
  margin: 0 0 1em;
}

.c-2 {
  color: var(--mustard);
}

.c-3 {
  color: var(--teal);
}

.c-4 {
  color: var(--burgundy);
}

.uc {
  text-transform: uppercase;
}

.font-lg {
  font-size: clamp(16px, 3.9vw, 8vw);
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .font-lg {
    font-size: min(max(1rem, 3.28125vw), 2.25rem);
  }
}
@media only screen and (min-width: 1200px) {
  .font-lg {
    font-size: min(max(1rem, 1.59375vw), 2.25rem);
  }
}

.font-xl {
  font-size: clamp(16px, 5.2vw, 8vw);
  font-weight: 800;
}
@media only screen and (min-width: 768px) {
  .font-xl {
    font-size: min(max(1rem, 4.375vw), 3rem);
  }
}
@media only screen and (min-width: 1200px) {
  .font-xl {
    font-size: min(max(1rem, 2.125vw), 3rem);
  }
}

.fw-med {
  font-weight: 500;
}

.fw-semi {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extra {
  font-weight: 800;
}

.smaller {
  font-weight: lighter;
  font-size: smaller;
}

.text-mustard {
  color: var(--mustard);
}

.header {
  position: relative;
}
.header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 6vw), 82% calc(100% - 6vw), 50% calc(100% - 2vw), 43% calc(100% - 4vw), 0% calc(100% - 4vw));
  background: url("../images/brick.jpg") center/cover no-repeat;
  z-index: 2;
  pointer-events: none;
}
.header .content {
  display: flex;
  justify-content: space-between;
  padding: var(--size) var(--size) 8.125vw;
}
.header-logo {
  width: 30vw;
}
@media only screen and (min-width: 1200px) {
  .header-logo {
    width: 20ch;
    max-width: 400px;
  }
}
.header .about-link {
  font-size: clamp(16px, 5.2vw, 8vw);
  font-weight: 800;
  text-decoration: underline;
}
@media only screen and (min-width: 768px) {
  .header .about-link {
    font-size: min(max(1rem, 4.375vw), 3rem);
  }
}
@media only screen and (min-width: 1200px) {
  .header .about-link {
    font-size: min(max(1rem, 2.125vw), 3rem);
  }
}

footer {
  background-color: var(--burgundy);
  color: var(--mustard);
  text-align: center;
  position: relative;
  padding: calc(8.125vw + var(--size-lg) * 2) var(--size-lg) var(--size-lg);
  clip-path: polygon(0vw 100vw, 100vw 100vw, 100vw 2vw, 73vw 4vw, 55vw 7vw, 44vw 3vw, 0vw 5vw);
}
footer .partners {
  display: grid;
  grid-template-columns: repeat(4, minmax(10vw, 200px));
  gap: var(--size-lg);
  align-items: center;
  justify-content: center;
}

.back-to-top {
  display: block;
  background: url("../images/back-to-top.svg") center/contain no-repeat;
  width: var(--size-lg);
  height: var(--size-lg);
  position: absolute;
  top: calc(8.125vw + var(--size-lg));
  right: var(--size-md);
}

main {
  position: relative;
  padding-block: 3vw 6vw;
}
main:before, main:after {
  content: "";
  background: url("../images/tear.png") center/auto 100% no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  height: 8.125vw;
  z-index: 3;
  pointer-events: none;
}
main:before {
  top: 0;
  transform: translateY(-100%);
}
main:after {
  top: 100%;
  transform: translateY(0);
}

.box-button {
  border: medium solid white;
  padding: 15px;
}

.no-bottom-padding {
  padding-bottom: 0;
}

.video-link {
  display: block;
  position: relative;
}
.video-link .thumbnail {
  position: relative;
}
.video-link .thumbnail:after {
  display: block;
  position: absolute;
  z-index: 999;
  content: url("../images/icon-play.svg");
  width: var(--size-xl);
  height: var(--size-xl);
  right: var(--size-lg);
  bottom: var(--size-lg);
}
@media only screen and (min-width: 768px) {
  .video-link .thumbnail:after {
    width: var(--size-md);
    height: var(--size-md);
    right: var(--size);
    bottom: var(--size);
  }
}
.video-link .video-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  transform: translateY(calc(0% - var(--size-sm)));
  margin-bottom: -4ch;
  background-color: var(--mustard);
  color: var(--burgundy);
  padding: 0.5em 1em;
  border-radius: 1rem;
  font-size: clamp(16px, 1.3vw, 8vw);
  font-weight: bold;
  line-height: 1;
  height: 6ch;
}
@media only screen and (min-width: 768px) {
  .video-link .video-title {
    font-size: min(max(1rem, 1.09375vw), 0.75rem);
  }
}
@media only screen and (min-width: 1200px) {
  .video-link .video-title {
    font-size: min(max(1rem, 0.53125vw), 0.75rem);
  }
}
@media only screen and (hover: hover) and (any-pointer: fine) {
  .video-link .video-title {
    opacity: 0;
    transition: opacity 0.1s ease;
  }
  .video-link:hover .video-title {
    opacity: 1;
  }
}

.page-home {
  background: var(--teal);
}
.page-home .header:before {
  background-image: url("../images/home-header-mobile.jpg");
}
.page-home .header .about-link {
  color: black;
}
.page-home main {
  padding-bottom: 0;
}
@media only screen and (max-width: 1199px) {
  .page-home .header .content {
    min-height: 20vh;
  }
  .page-home .header-logo {
    align-self: center;
  }
  .page-home .header .about-link {
    font-size: 4vw;
  }
}
@media only screen and (min-width: 1200px) {
  .page-home main:before {
    background: url("../images/tear-home.png") center bottom/auto 100% no-repeat;
    height: 10.8vw;
  }
  .page-home .header:before {
    background-image: url("../images/home-header.jpg");
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 7vw), 85% calc(100% - 7.5vw), 81% calc(100% - 7.4vw), 63% calc(100% - 8vw), 59% calc(100% - 6vw), 53% calc(100% - 2vw), 47% calc(100% - 2vw), 40% calc(100% - 6vw), 26% calc(100% - 6vw), 26% calc(100% - 7vw), 9% calc(100% - 8vw), 0% calc(100% - 9vw));
  }
  .page-home .header .content {
    padding-bottom: 12.8vw;
  }
}
.page-home .photo {
  position: absolute;
  top: 100%;
  width: 18vw;
  max-width: 400px;
  z-index: 4;
  pointer-events: none;
}
.page-home .photo-1 {
  left: 52%;
  transform: rotate(-10deg) translate(-100%, -50%);
}
.page-home .photo-2 {
  right: 52%;
  transform: rotate(10deg) translate(100%, -50%);
}

.icon-down {
  display: inline-block;
  content: url("../images/icon-double-arrow-down.svg");
  width: var(--size-lg);
  height: var(--size-lg);
  margin-bottom: var(--size);
}

.home-hero {
  position: relative;
  margin-top: -8.125vw;
  padding-bottom: 80vw;
  font-size: clamp(16px, 5.2vw, 8vw);
  font-weight: 800;
}
@media only screen and (min-width: 768px) {
  .home-hero {
    font-size: min(max(1rem, 4.375vw), 3rem);
  }
}
@media only screen and (min-width: 1200px) {
  .home-hero {
    font-size: min(max(1rem, 2.125vw), 3rem);
  }
}
.home-hero:before {
  content: "";
  background: url("../images/home-hero-mobile.jpg") center bottom/cover no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 6vw), 82% calc(100% - 6vw), 50% calc(100% - 2vw), 43% calc(100% - 4vw), 0% calc(100% - 4vw));
  z-index: 1;
  pointer-events: none;
}
.home-hero .content {
  padding: var(--size-xl) var(--size-xl) 8.125vw;
  text-align: center;
}
.home-hero .photo-5 {
  display: none;
}
.home-hero .icon-play-colored {
  display: inline-block;
  content: url("../images/icon-play-color.svg");
  width: var(--size-lg);
  height: var(--size-lg);
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}
.home-hero .icon-play-colored:hover {
  transform: scale(1.15);
}
@media only screen and (max-width: 1199px) {
  .home-hero {
    padding-bottom: 90vw;
  }
  .home-hero .when {
    color: var(--mustard);
    top: var(--size-lg);
    right: 5vw;
    font-size: 3vw;
    font-weight: 700;
  }
}
@media only screen and (min-width: 1200px) {
  .home-hero {
    margin-top: -10.8vw;
    padding-bottom: 15vw;
  }
  .home-hero:before {
    background: url("../images/home-hero.jpg") center bottom/cover no-repeat;
    clip-path: polygon(0vw calc(100% - 0.5vw), 9vw calc(100% - 2.5vw), 16vw calc(100% - 2vw), 22vw 100%, 33vw calc(100% - 2vw), 43vw calc(100% - 2.8vw), 56vw 100%, 64vw 100%, 69vw calc(100% - 2vw), 75vw calc(100% - 3vw), 81vw calc(100% - 1vw), 100vw calc(100% - 5vw), 100vw 1vw, 73vw 0vw, 60vw 0.3vw, 54vw 7vw, 52vw 7vw, 41vw 2vw, 33vw 2vw, 0vw -2vw);
    z-index: 2;
  }
  .home-hero .photo-5 {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) rotate(-45deg);
  }
  .home-hero .content {
    display: flex;
    justify-content: space-between;
    padding: var(--size-lg) var(--size) var(--size-xl);
  }
  .home-hero .discover {
    width: 24vw;
    text-align: left;
    margin: 0 0 0 10vw;
  }
  .home-hero .when {
    text-align: right;
  }
  .home-hero .icon-play-colored {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(100%, -50%);
  }
  .home-hero .icon-play-colored:hover {
    transform: translate(100%, -50%) scale(1.15);
  }
}

.home-intro {
  position: relative;
  padding-block: 12vw;
  color: black;
  text-align: center;
  font-size: clamp(16px, 5.2vw, 8vw);
  font-weight: 800;
}
@media only screen and (min-width: 768px) {
  .home-intro {
    font-size: min(max(1rem, 4.375vw), 3rem);
  }
}
@media only screen and (min-width: 1200px) {
  .home-intro {
    font-size: min(max(1rem, 2.125vw), 3rem);
  }
}
.home-intro:before {
  content: "";
  background-color: var(--mustard);
  position: absolute;
  top: -8.125vw;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0vw 100vw, 100vw 100vw, 100vw 2vw, 73vw 4vw, 55vw 7vw, 44vw 3vw, 0vw 5vw);
  z-index: 1;
}
.home-intro .container {
  max-width: 60vw;
}
@media only screen and (max-width: 1199px) {
  .home-intro:after {
    content: "";
    background: url("../images/tear.png") center/200% no-repeat;
    position: absolute;
    top: 0;
    transform: translateY(-75%);
    left: 0;
    right: 0;
    height: 16.25vw;
    z-index: 3;
    pointer-events: none;
  }
}
.home-intro .photo-6 {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-5deg) translate(-15%, -12vw);
}
.home-intro .photo-7 {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(5deg) translate(25%, 5%) scale(0.8);
}
@media only screen and (min-width: 1200px) {
  .home-intro {
    padding-block: var(--size-md);
  }
  .home-intro .photo-6 {
    transform: rotate(-5deg) translate(-15%, 2vw);
  }
  .home-intro .photo-7 {
    top: 100%;
    transform: rotate(5deg) translate(-25%, -25%) scale(0.8);
  }
}

.home-videos {
  position: relative;
  z-index: 2;
  background: var(--teal);
  padding-block: 0 var(--size-lg);
  text-align: center;
}
.home-videos .filters {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: var(--size);
  font-size: clamp(16px, 1.5166666667vw, 8vw);
  transform: translateY(-2em);
}
@media only screen and (max-width: 1199px) {
  .home-videos .filters {
    width: var(--content-width);
  }
}
@media only screen and (min-width: 768px) {
  .home-videos .filters {
    font-size: min(max(1rem, 1.2760416667vw), 0.875rem);
  }
}
@media only screen and (min-width: 1200px) {
  .home-videos .filters {
    font-size: min(max(1rem, 0.6197916667vw), 0.875rem);
  }
}
.home-videos .filters .btn {
  width: calc(50% - var(--size));
  min-height: 4em;
}
@media only screen and (min-width: 1200px) {
  .home-videos .filters .btn {
    width: calc(25% - var(--size));
  }
}
.home-videos #videos {
  overflow: hidden;
  border-radius: var(--size-lg);
  display: flex;
  flex-wrap: wrap;
  margin: 0 var(--size-md);
  padding: var(--size) var(--size-sm) 10vw;
}
.home-videos .video-link {
  margin: var(--size);
  transform: scale(1);
  transition: transform 0.2s;
}
.home-videos .video-link.filter-out {
  margin: 0;
  max-width: 0;
  transform: scale(0);
}
.home-videos .video-link.filter-out:after {
  content: none;
}
@media only screen and (min-width: 768px) {
  .home-videos .video-link {
    width: calc(50% - var(--size) * 2);
    margin: var(--size);
  }
  .home-videos .video-link[data-even="1"] {
    top: 10vw;
  }
}
@media only screen and (min-width: 1200px) {
  .home-videos #videos {
    padding: var(--size-md) var(--size-md) 12rem;
  }
  .home-videos .video-link {
    width: calc(25% - var(--size-sm) * 2);
    margin: var(--size-sm);
  }
  .home-videos .video-link[data-even="1"] {
    top: 10rem;
  }
}

dialog {
  background: transparent;
  border: 0;
  color: inherit;
  max-width: unset;
  padding: 0;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0);
}

#video-overlay {
  position: absolute;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 0;
  padding-bottom: 56.25%;
  margin: 0;
  overflow: hidden;
  transform: translateY(-100%);
  transition: transform 0.4s ease-in-out;
}
#video-overlay .video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
#video-overlay .btn-close {
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  right: var(--size);
  top: var(--size);
  width: var(--size-md);
  height: var(--size-md);
  cursor: pointer;
  z-index: 999;
}
#video-overlay .btn-close:before {
  content: url("/images/close.svg");
}

html.modal-open #video-overlay[open] {
  transform: translateY(0%);
}

.page-about p.links a {
  color: var(--mustard);
}
.page-about .intro {
  position: relative;
  padding-top: var(--size-lg);
}
@media only screen and (max-width: 1199px) {
  .page-about .intro .container {
    padding-bottom: 40vw;
  }
  .page-about .intro .photo-3,
  .page-about .intro .photo-4 {
    position: absolute;
    left: 50%;
    top: 100%;
    max-width: 40vw;
  }
  .page-about .intro .photo-3 {
    transform: translate(-100%, -120%) rotate(-10deg);
  }
  .page-about .intro .photo-4 {
    transform: translate(5%, -110%) rotate(10deg);
  }
}
@media only screen and (min-width: 1200px) {
  .page-about .intro .container {
    min-height: 22vw;
    max-width: unset;
    margin-left: calc(50vw - var(--content-width) * 0.5);
    margin-right: 30vw;
  }
  .page-about .intro .photo-3,
  .page-about .intro .photo-4 {
    position: absolute;
    right: 0%;
    top: 100%;
    max-width: 20vw;
  }
  .page-about .intro .photo-3 {
    transform: translate(10%, -180%) rotate(-10deg);
  }
  .page-about .intro .photo-4 {
    transform: translate(5%, -80%) rotate(10deg);
  }
}
.page-about .logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--size);
  margin: 0 auto;
}
.page-about .logos > a {
  max-width: calc(50% - var(--size));
}
@media only screen and (min-width: 1200px) {
  .page-about .logos > a {
    max-width: calc(20% - var(--size));
  }
}
.page-about .credits {
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .page-about .credits {
    /* put back if the images are uncommented
    .container {
        padding-top: 40vw;
    }
    */
  }
  .page-about .credits .photo-5,
  .page-about .credits .photo-6 {
    position: absolute;
    left: 50%;
    top: 0;
    max-width: 40vw;
  }
  .page-about .credits .photo-5 {
    transform: translate(-100%, 20%) rotate(10deg);
  }
  .page-about .credits .photo-6 {
    transform: translate(5%, 10%) rotate(-10deg);
  }
}
@media only screen and (min-width: 1200px) {
  .page-about .credits .photo-5,
  .page-about .credits .photo-6 {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 20vw;
  }
  .page-about .credits .photo-6 {
    transform: translate(15%, -20%) rotate(-10deg);
    max-width: 16vw;
  }
  .page-about .credits .photo-5 {
    transform: translate(100%, 0) rotate(10deg);
  }
}
.page-about .credits-content h2 {
  display: flex;
  align-items: center;
  gap: var(--size-sm);
  padding: var(--size) 0;
}
.page-about .credits-content h2:after {
  display: inline-block;
  content: url("../images/icon-arrow-down.svg");
  width: var(--size);
}
.page-about .credits-content strong {
  color: var(--mustard);
}
.page-about .credits-content > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  column-count: 2;
  column-gap: var(--size-lg);
}
.page-about .credits-content > ul li {
  margin-bottom: initial;
}
.page-about .credits-content > ul li:before {
  content: none;
}
@media only screen and (min-width: 1200px) {
  .page-about .credits-content > ul {
    column-count: 3;
    column-gap: var(--size-lg);
    margin: 0 0 var(--size) 0;
  }
}
.page-about .credits-content > ul > li {
  display: inline-block;
  width: 100%;
}
.page-about .credits-content > ul ul {
  color: var(--teal);
  margin-bottom: var(--size-lg);
}
.page-about main {
  padding-bottom: 14.125vw;
}
.page-about .photo {
  position: absolute;
  top: calc(100% - 8.125vw);
  z-index: 4;
  pointer-events: none;
}
.page-about .photo-1 {
  width: 35vw;
  right: -5vw;
  transform: rotate(-10deg) translateY(-8.125vw);
}
.page-about .photo-2 {
  width: 25vw;
  left: 5vw;
  transform: rotate(10deg);
}

.page-single {
  background: var(--burgundy);
}
.page-single main {
  padding-bottom: 0;
  margin-bottom: -8.125vw;
}
.page-single main:after {
  transform: translateY(-100%);
}

.main-video {
  position: relative;
  text-align: center;
}
.main-video:before {
  content: "";
  background-color: var(--teal);
  position: absolute;
  top: 40%;
  bottom: -5px;
  left: 0;
  right: 0;
  clip-path: polygon(0% 4vw, 10% 3vw, 21% 6vw, 43% 3vw, 55% 6vw, 70% 2.5vw, 82% 5vw, 100% 1vw, 100% 100%, 0% 100%);
}
.main-video:after {
  content: "";
  background: url("../images/tear.png") center/auto 100% no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(0);
  height: 8.125vw;
  z-index: 3;
  pointer-events: none;
}
.main-video .video-title {
  text-align: left;
}

.page-single .video-wrapper {
  position: relative;
  z-index: 999;
  display: inline-block;
}
@media only screen and (min-width: 1200px) {
  .page-single .video-wrapper {
    height: 80vh;
  }
}

.plyr--video {
  --plyr-video-control-background-hover: transparent;
  --plyr-video-controls-background: #000;
  --plyr-range-fill-background: #fff;
}
.plyr--video .plyr__poster {
  background-size: cover;
}
.plyr--video .plyr__control--overlaid {
  --btn-size: var(--size-lg);
  background: url("../images/icon-play-color.svg") !important;
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 0;
}
.plyr--video .plyr__control--overlaid:hover {
  --btn-size: calc(var(--size-lg) * 1.15);
}
.plyr--video .plyr__control--overlaid svg {
  display: none;
}
.plyr--video .plyr__controls {
  display: grid;
  grid-template-areas: "play progress progress progress duration" "play sound settings pip fullscreen";
  grid-template-columns: min-content 1fr min-content;
  justify-content: stretch;
  padding: var(--size) !important;
}
.plyr--video .plyr__controls [data-plyr=play] {
  grid-area: play;
}
.plyr--video .plyr__controls [data-plyr=play] svg {
  width: var(--size-sm);
  height: var(--size-sm);
}
.plyr--video .plyr__controls .plyr__volume {
  width: 50%;
  max-width: 200px;
  grid-area: sound;
}
.plyr--video .plyr__controls .plyr__progress__container {
  grid-area: progress;
}
.plyr--video .plyr__controls .plyr__time {
  grid-area: duration;
}
.plyr--video .plyr__controls .plyr__menu {
  grid-area: settings;
}
.plyr--video .plyr__controls [data-plyr=pip] {
  grid-area: pip;
}
.plyr--video .plyr__controls [data-plyr=fullscreen] {
  grid-area: fullscreen;
}

.video-description {
  position: relative;
  padding: 3vw 0 12.125vw;
  margin-bottom: -8.125vw;
  text-align: left;
}
.video-description:before {
  content: "";
  background-color: var(--teal);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 65% calc(100% - 2vw), 44% calc(100% - 4vw), 0% 100%);
}
.video-description:after {
  content: "";
  background: url("../images/tear.png") center/auto 100% no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  transform: translateY(-100%);
  height: 8.125vw;
  z-index: 3;
  pointer-events: none;
}

.related-videos {
  position: relative;
  padding: calc(var(--size-lg) + 8.125vw) 0;
}
.related-videos:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0% 4vw, 10% 3vw, 21% 6vw, 43% 3vw, 55% 6vw, 70% 2.5vw, 82% 5vw, 100% 1vw, 100% 100%, 65% calc(100% - 2vw), 44% calc(100% - 4vw), 0% 100%);
}
.related-videos .more {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-lg);
  margin: var(--size-lg) auto 0;
}
@media only screen and (min-width: 768px) {
  .related-videos .more {
    grid-template-columns: repeat(3, 1fr);
  }
}
.related-videos .more .video-link {
  margin: var(--size-sm) auto var(--size);
}