@import "web-fonts";


h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.25em;
}

p {
    margin: 0 0 1em;
}

.c-2 { color: var(--mustard); }
.c-3 { color: var(--teal); }
.c-4 { color: var(--burgundy); }

.uc { text-transform: uppercase; }

.font-lg {
    @include fs(36);
    font-weight: $fw-bold;
}

.font-xl {
    @include fs(48);
    font-weight: $fw-xbold;
}

.fw-med { font-weight: $fw-medium; }
.fw-semi { font-weight: $fw-semi; }
.fw-bold { font-weight: $fw-bold; }
.fw-extra { font-weight: $fw-xbold; }

.smaller
{
    font-weight: lighter;
    font-size:smaller;
}

.text-mustard
{
    color:var(--mustard);
}
