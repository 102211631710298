
.home-videos {
    position: relative;
    z-index: $zindex_below_tear;
    background: var(--teal);
    padding-block: 0 var(--size-lg);
    text-align: center;

    .filters {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        gap: var(--size);

        @include media(small) {
            width: var(--content-width);
        }

        @include fs(14);
        transform: translateY(-2em);

        .btn {
            width: calc(50% - var(--size));
            min-height: 4em;
        }

        @include media(desktop) {
            .btn {
                width: calc(25% - var(--size));
            }
        }
    }

    #videos {
        @extend %bg-texture;

        overflow: hidden;
        border-radius: var(--size-lg);

        display: flex;
        flex-wrap: wrap;

        margin: 0 var(--size-md);
        padding: var(--size) var(--size-sm) 10vw;
    }

    .video-link {
        margin: var(--size);

        transform: scale(1);
        transition: transform .2s;

        &.filter-out {
            margin: 0;
            max-width: 0;
            transform: scale(0);

            &:after {
                content: none;
            }
        }
    }

    @include media(tablet) {
        .video-link {
            width: calc(50% - var(--size) * 2);
            margin: var(--size);

            &[data-even='1'] {
                top: 10vw; // magic number here
            }
        }
    }

    // Desktop spacing is a bit bigger
    @include media(desktop) {
        #videos {
            padding: var(--size-md) var(--size-md) 12rem;
        }
        .video-link {
            width: calc(25% - var(--size-sm) * 2);
            margin: var(--size-sm);

            &[data-even='1'] {
                top: 10rem; // magic number here
            }
        }
    }
}
