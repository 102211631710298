.page-about {
    @extend %bg-texture;

    p.links a {

        color: var(--mustard);;

    }
    .intro {
        position: relative;
        padding-top: var(--size-lg);

        @include media(small) {
            .container {
                padding-bottom: 40vw;
            }

            .photo-3,
            .photo-4 {
                position: absolute;
                left: 50%; top: 100%;
                max-width: 40vw;
            }
            .photo-3 { transform: translate(-100%, -120%) rotate(-10deg); }
            .photo-4 { transform: translate(5%, -110%) rotate(10deg); }
        }

        @include media(desktop) {
            .container {
                min-height: 22vw;
                max-width: unset;
                margin-left: calc(50vw - (var(--content-width) * 0.5));
                margin-right: 30vw;
            }

            .photo-3,
            .photo-4 {
                position: absolute;
                right: 0%; top: 100%;
                max-width: 20vw;
            }

            .photo-3 { transform: translate(10%, -180%) rotate(-10deg); }
            .photo-4 { transform: translate(5%, -80%) rotate(10deg); }
        }
    }

    .logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: var(--size);
        margin: 0 auto;

        > a {
            max-width: calc(50% - var(--size));

            @include media(desktop) {
                max-width: calc(20% - var(--size));
            }
        }

    }

    .credits {
        position: relative;

        @include media(small) {
            /* put back if the images are uncommented
            .container {
                padding-top: 40vw;
            }
            */

            .photo-5,
            .photo-6 {
                position: absolute;
                left: 50%; top: 0;
                max-width: 40vw;
            }
            .photo-5 { transform: translate(-100%, 20%) rotate(10deg); }
            .photo-6 { transform: translate(5%, 10%) rotate(-10deg); }
        }

        @include media(desktop) {

            .photo-5,
            .photo-6 {
                position: absolute;
                right: 0; top: 0;
                max-width: 20vw;
            }

            .photo-6 {
                transform: translate(15%, -20%) rotate(-10deg);
                max-width: 16vw;
            }
            .photo-5 {
                transform: translate(100%, 0) rotate(10deg);
            }
        }

        &-content {

            h2 {
                display: flex;
                align-items: center;
                gap: var(--size-sm);
                padding: var(--size) 0;

                &:after {
                    display: inline-block;
                    content: url('../images/icon-arrow-down.svg');
                    width: var(--size);
                }
            }

            strong
            {
                color: var(--mustard);

            }
            > ul {
                @include list-unstyle;

                // msonry-style
                column-count: 2;
                column-gap: var(--size-lg);

                @include media(desktop) {
                    column-count: 3;
                    column-gap: var(--size-lg);
                    margin: 0 0 var(--size) 0;
                }

                // don't cut in columns
                > li {
                    display: inline-block;
                    width: 100%;
                }

                ul {
                    color: var(--teal);
                    margin-bottom: var(--size-lg);
                }
            }

        }
    }

    // Pics at the bottom
    main {
        padding-bottom: 6vw + $tear_height;
    }

    .photo {
        position: absolute;
        top: calc(100% - #{$tear_height});

        // sit on top, do not disturb
        z-index: $zindex_above_tear;
        pointer-events: none;
    }

    .photo-1 {
        width: 35vw;
        right: -5vw;
        transform: rotate(-10deg) translateY(-$tear_height);
    }
    .photo-2 {
        width: 25vw;
        left: 5vw;
        transform: rotate(10deg);
    }

}
