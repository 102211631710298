
.home-hero {
    position: relative;
    margin-top: -$tear_height;
    padding-bottom: 80vw;

    @include fs(48);
    font-weight: $fw-xbold;


    &:before {
        content: '';
        background: url('../images/home-hero-mobile.jpg') center bottom / cover no-repeat;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;

        // clip along the paper tear. polygon: X Y, X Y etc.
        // can use vw all around here cause the tearing is at the top (no need to calc height)
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 6vw), 82%  calc(100% - 6vw), 50%  calc(100% - 2vw), 43%  calc(100% - 4vw), 0% calc(100% - 4vw));

        // sit on top, do not disturb
        z-index: $zindex_below_all;
        pointer-events: none;
    }

    .content {
        padding: var(--size-xl) var(--size-xl) $tear_height;
        text-align: center;
    }

    .photo-5 { display: none; }


    .icon-play-colored {
        display: inline-block;
        content: url('../images/icon-play-color.svg');
        width: var(--size-lg); height: var(--size-lg);

        z-index: $zindex_above_all;
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.15);
        }

    }

    @include media(small) {
        .when {
            color: var(--mustard);
            //position: absolute;
            top: var(--size-lg);
            right: 5vw;
            font-size: 3vw;
            font-weight: $fw-bold;
        }

        padding-bottom: 90vw;
    }

    @include media(desktop) {
        margin-top: -10.8vw;
        padding-bottom: 15vw;

        &:before {
            background: url('../images/home-hero.jpg') center bottom / cover no-repeat;
            clip-path: polygon(0vw calc(100% - 0.5vw), 9vw calc(100% - 2.5vw), 16vw calc(100% - 2vw), 22vw 100%, 33vw calc(100% - 2vw), 43vw calc(100% - 2.8vw), 56vw 100%, 64vw 100%, 69vw calc(100% - 2vw), 75vw calc(100% - 3vw), 81vw calc(100% - 1vw), 100vw calc(100% - 5vw), 100vw 1vw, 73vw 0vw, 60vw 0.3vw, 54vw 7vw, 52vw 7vw, 41vw 2vw, 33vw 2vw, 0vw -2vw);
            z-index: 2;
        }

        .photo-5 {
            display: block;
            position: absolute;
            top: 0; left: 50%;
            transform: translate(-50%, 0) rotate(-45deg);
        }

        .content {
            display: flex;
            justify-content: space-between;
            padding: var(--size-lg) var(--size) var(--size-xl);
        }

        .discover {
            width: 24vw;
            text-align: left;
            margin: 0 0 0 10vw;
        }

        .when {
            text-align: right;
        }

        .icon-play-colored {
            position: absolute;
            left: 50%; top: 50%;
            transform: translate(100%, -50%);

            &:hover {
                transform: translate(100%, -50%) scale(1.15);
            }

        }
    }
}
