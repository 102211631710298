* { box-sizing: border-box; }

html {
    background-color: var(--color-page);
    color: var(--color-text);
    font-family: var(--font);
    line-height: 1.3;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @include fs(30);
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow-x: hidden;
    margin: 0;

    .no-scroll & {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}
