
.video-description {
    position: relative;
    padding: 3vw 0 $tear_height + 4vw;
    margin-bottom: -$tear_height;
    text-align: left;

    &:before {
        content: '';
        background-color: var(--teal);

        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;

        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 65% calc(100% - 2vw), 44% calc(100% - 4vw), 0% 100%);
    }

    &:after {
        content: '';
        background: url('../images/tear.png') center / auto 100% no-repeat;
        position: absolute;
        left: 0; right: 0;
        top: 100%; transform: translateY(-100%);
        height: $tear_height;

        // sit on top, do not disturb
        z-index: $zindex_tear;
        pointer-events: none;
    }
}
