// generated with https://gwfh.mranftl.com/fonts/raleway?subsets=latin

/* raleway-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/raleway-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/raleway-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v29-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/raleway-v29-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/raleway-v29-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/raleway-v29-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/raleway-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/raleway-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v29-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/raleway-v29-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/raleway-v29-latin-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/raleway-v29-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/raleway-v29-latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/raleway-v29-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v29-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/raleway-v29-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/raleway-v29-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/raleway-v29-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/raleway-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/raleway-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v29-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/raleway-v29-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/raleway-v29-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/raleway-v29-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/raleway-v29-latin-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/raleway-v29-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/raleway-v29-latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/raleway-v29-latin-800.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/raleway-v29-latin-800.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/raleway-v29-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}
