
.related-videos {
    position: relative;
    padding: calc(var(--size-lg) + $tear_height) 0;

    &:before {
        content: '';
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        @extend %bg-texture;

        clip-path: polygon(0% 4vw, 10% 3vw, 21% 6vw, 43% 3vw, 55% 6vw, 70% 2.5vw, 82% 5vw, 100% 1vw, 100% 100%, 65% calc(100% - 2vw), 44% calc(100% - 4vw), 0% 100%);
    }

    .more {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--size-lg);
        margin: var(--size-lg) auto 0;

        @include media(tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        .video-link {
            margin: var(--size-sm) auto var(--size);
        }
    }

}
