.header {
    position: relative;


    // BG with paper tear
    &:before {
        content: '';
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;

        // clip along the paper tear. polygon: X Y, X Y etc.
        // since the tear's height is in vw and the polygon's Y coordinates are % values of height, we have to use calc here
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 6vw), 82%  calc(100% - 6vw), 50%  calc(100% - 2vw), 43%  calc(100% - 4vw), 0% calc(100% - 4vw));
        background: url('../images/brick.jpg') center / cover no-repeat;

        // sit on top, do not disturb
        z-index: $zindex_below_tear;
        pointer-events: none;
    }

    .content {
        display: flex;
        justify-content: space-between;
        padding: var(--size) var(--size) $tear_height;
    }

    &-logo {
        width: 30vw;

        @include media(desktop) {
            width: 20ch;
            max-width: 400px;
        }
    }

    .about-link {
        @include fs(48);
        font-weight: $fw-xbold;
        text-decoration: underline;
    }

}
