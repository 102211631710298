
.sr-only {
    @extend %screen-reader-text;
}

.container {
    @extend %container;
}

.centered {
    @extend %container;
    display: inline-block;
}

.container,
.content {
    position: relative;
    z-index: $zindex_above_tear;
}

.photo {
    border: var(--size-xs) solid white;
    box-shadow: 0 0 0.25rem 0.25rem rgba(0,0,0,0.15);

    @include media(mobile) {
        border: 1vw solid white;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; // never stop centering

    background-color: var(--burgundy);
    border: 2px solid var(--burgundy);
    border-radius: 2em;
    padding: 0.5em 2em;

    &:active,
    &.active {
        border-color: var(--color-text);
    }
}
