.main-video {
    position: relative;

    &:before {
        content: '';
        background-color: var(--teal);

        position: absolute;
        top: 40%; bottom: -5px;
        left: 0; right: 0;

        clip-path: polygon(0% 4vw, 10% 3vw, 21% 6vw, 43% 3vw, 55% 6vw, 70% 2.5vw, 82% 5vw, 100% 1vw, 100% 100%, 0% 100%);
    }

    &:after {
        content: '';
        background: url('../images/tear.png') center / auto 100% no-repeat;
        position: absolute;
        left: 0; right: 0;
        top: 40%; transform: translateY(0);
        height: $tear_height;

        // sit on top, do not disturb
        z-index: $zindex_tear;
        pointer-events: none;
    }

    .video-title,
    .video-player {
        @extend %container;
    }

    text-align: center;
    .video-title { text-align: left; }
}
