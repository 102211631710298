
:root {
    --color-page: #000000;
    --color-text: #ffffff;

    --teal: #607b7a;
    --burgundy: #662a29;
    --mustard: #f1aa4c;

    --font: 'Raleway', sans-serif;
    --border-radius: 8vw;
    --content-width: 86vw;

    --size: 4vw;
    --size-xs: 2vw;
    --size-sm: 3vw;
    --size-md: 6vw;
    --size-lg: 8vw;
    --size-xl: 15vw;

    @include media(desktop) {
        --border-radius: 3rem;
        --content-width: 75vw;

        --size: 2rem;
        --size-xs: 0.5rem;
        --size-sm: 1rem;
        --size-md: 3rem;
        --size-lg: 4rem;
        --size-xl: 7rem;
    }

    @include media(xlarge) {
        --border-radius: 5rem;
        --content-width: 1700px;
    }
}

$tear_height: 8.125vw;

$zindex_below_all: 1; // for backgrounds that must be below everything
$zindex_below_tear: 2; // above bg, but below content & decorations
$zindex_tear: 3; // decorations
$zindex_above_tear: 4; // content
$zindex_above_all: 999; // special case

$fw-medium: 500;
$fw-semi: 600;
$fw-bold: 700;
$fw-xbold: 800;
