.page-single {
    background: var(--burgundy);
    main {
        padding-bottom: 0;
        margin-bottom: -8.125vw;
        &:after { transform: translateY(-100%); }
    }
}

@import "./single/main-video";
@import "./single/video-player";
@import "./single/video-description";
@import "./single/related-videos";
