
.page-home {
    background: var(--teal);

    // Homepage header is a bit different
    .header {
        &:before { background-image: url('../images/home-header-mobile.jpg'); }
        .about-link { color: black; }
    }

    main {
        padding-bottom: 0;
    }

    @include media(small) {
        .header {
            .content { min-height: 20vh; }
            &-logo { align-self: center; }
            .about-link { font-size: 4vw; }
        }
    }

    @include media(desktop) {

        main:before {
            background: url('../images/tear-home.png') center bottom / auto 100% no-repeat;
            height: 10.8vw;
        }

        .header {
            // #ihdts
            &:before {
                background-image: url('../images/home-header.jpg');
                clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 7vw), 85% calc(100% - 7.5vw), 81% calc(100% - 7.4vw), 63% calc(100% - 8vw), 59% calc(100% - 6vw), 53% calc(100% - 2vw), 47% calc(100% - 2vw), 40% calc(100% - 6vw), 26% calc(100% - 6vw), 26% calc(100% - 7vw), 9% calc(100% - 8vw), 0% calc(100% - 9vw));
            }
            .content { padding-bottom: 12.8vw; }
        }
    }

    // Pics at the bottom
    .photo {
        position: absolute;
        top: 100%;
        width: 18vw;
        max-width: 400px;

        // sit on top, do not disturb
        z-index: $zindex_above_tear;
        pointer-events: none;
    }

    .photo-1 {
        left: 52%;
        transform: rotate(-10deg) translate(-100%, -50%);
    }
    .photo-2 {
        right: 52%;
        transform: rotate(10deg) translate(100%, -50%);
    }

}

.icon-down {
    display: inline-block;
    content: url('../images/icon-double-arrow-down.svg');
    width: var(--size-lg); height: var(--size-lg);
    margin-bottom: var(--size);
}


@import "./home/home-hero";
@import "./home/home-intro";
@import "./home/home-videos";
@import "./home/video-overlay";
