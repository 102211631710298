
.video-link {
    display: block;
    position: relative;

    img {
        @extend %square;
        @extend %rounded;
        @extend %bordered;
    }

    .thumbnail {
        position: relative;

        &:after {
            display: block;
            position: absolute;
            z-index: $zindex_above_all;
            content: url('../images/icon-play.svg');

            width: var(--size-xl); height: var(--size-xl);
            right: var(--size-lg); bottom: var(--size-lg);

            @include media(tablet) {
                width: var(--size-md); height: var(--size-md);
                right: var(--size); bottom: var(--size);
            }
        }
    }

    .video-title {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;

        position: relative;
        transform: translateY(calc( 0% - var(--size-sm) ));
        margin-bottom: -4ch;

        background-color: var(--mustard);
        color: var(--burgundy);
        padding: 0.5em 1em;
        border-radius: 1rem;

        @include fs(12);
        font-weight: bold;
        line-height: 1;
        height: 6ch;
    }

    @include media(hover) {
        .video-title {
            opacity: 0;
            transition: opacity 0.1s ease;
        }
        &:hover {
            .video-title {
                opacity: 1;
            }
        }
    }
}
