main {
  position: relative;
  padding-block: 3vw 6vw;

  &:before,
  &:after {
    content: '';
    background: url('../images/tear.png') center / auto 100% no-repeat;
    position: absolute;
    left: 0; right: 0;
    height: $tear_height;

    // sit on top, do not disturb
    z-index: $zindex_tear;
    pointer-events: none;
  }

  &:before { top: 0; transform: translateY(-100%); }
  &:after { top: 100%; transform: translateY(0); }
}

.box-button
{
    border: medium solid white;
    padding: 15px;
}

.no-bottom-padding
{
    padding-bottom: 0;
}
