
// Media queries helper
// eg.: @include media(phone) {...}
$mediaqueries : (
    hover: "(hover: hover) and (any-pointer: fine)",
    xs: "(max-width: 575px)",
    phone: "(max-width: 767px)",
    tablet: "(min-width: 768px)",
    mobile: "(max-width: 991px)",
    small: "(max-width: 1199px)",
    desktop: "(min-width: 1200px)",
    large: "(min-width: 1600px)",
    xlarge: "(min-width: 1920px)",
    xxlarge: "(min-width: 2200px)"
);

@mixin media( $media ) {
    @media only screen and #{map-get($mediaqueries, $media)} {
        @content;
    }
}

// Magic responsive fonts
@mixin fs($size) {
    $vw_fs: calc($size/1920) * 100;
    $px_fs: calc($size/16);

    font-size: clamp(16px, #{calc($size/600) * 65 +'vw'}, 8vw);

    @include media(tablet) {
        font-size: #{'min(max(1rem, #{($vw_fs * 1.75)+'vw'}), #{$px_fs+'rem'})'};
    }

    @include media(desktop) {
        font-size: #{'min(max(1rem, #{($vw_fs * 0.85)+'vw'}), #{$px_fs+'rem'})'};
    }

}

// Accessibility
%screen-reader-text {
    position: absolute !important;
    top: auto;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    white-space: nowrap;
}


// Unstyle lists
@mixin list-unstyle {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin-bottom: initial;
        &:before { content: none; }
    }
}

@mixin btn-unstyle {
    background: transparent;
    padding: 0; border: 0;
}

%container {
    max-width: var(--content-width);
    margin-inline: auto;
}

%square {
    aspect-ratio: 1/1;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
}

%rounded {
    overflow: hidden;
    border-radius: var(--border-radius);
}

%bordered {
    border: 2px solid;
}

%bg-texture {
    background: url('/images/texture.jpg') center / cover no-repeat;
    background-attachment: fixed;
}
