
.page-single .video-wrapper {
    @extend %rounded;
    @extend %bordered;

    // for some weird reason, Safari will only honor the rounded corners with this.
    position: relative;
    z-index: $zindex_above_all;

    display: inline-block;
    @include media(desktop) {
        height: 80vh;
    }
}

// The actual player (plyr - https://plyr.io/)
.plyr--video {

    --plyr-video-control-background-hover: transparent;
    --plyr-video-controls-background: #000;
    --plyr-range-fill-background: #fff;

    .plyr__poster {
        background-size: cover;
    }

    // play button
    .plyr__control--overlaid {
        --btn-size: var(--size-lg);

        background: url('../images/icon-play-color.svg') !important;
        width: var(--btn-size); height: var(--btn-size);
        border-radius: 0;

        &:hover {
            --btn-size: calc(var(--size-lg) * 1.15); // manual "scale"
        }

        svg { display: none; } // hide normal icon
    }

    // controls bar
    .plyr__controls {
        display: grid;
        grid-template-areas: 'play progress progress progress duration' 'play sound settings pip fullscreen';
        grid-template-columns: min-content 1fr min-content;
        justify-content: stretch;
        padding: var(--size) !important;

        // play button
        [data-plyr=play] {
            grid-area: play;

            // make my icon bigger
            svg {
                width: var(--size-sm);
                height: var(--size-sm);
            }
        }
        .plyr__volume   {
            width: 50%; max-width: 200px;
            grid-area: sound;
        }

        .plyr__progress__container { grid-area: progress; }
        .plyr__time     { grid-area: duration; }
        .plyr__menu     { grid-area: settings; }
        [data-plyr=pip] { grid-area: pip; }
        [data-plyr=fullscreen] { grid-area: fullscreen; }
    }

}
